















import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Home extends Vue {
  get redirectUrl() {
    return this.$route.query.redirectUrl;
  }

  mounted() {
    setTimeout(() => {
      this.$router.push(this.redirectUrl as string);
    }, 2000);
  }
}
